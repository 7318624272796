import React from "react";

import { useTranslation, Trans } from "react-i18next";

import main from '../../img/Iras/main.png'

import Fade from "react-reveal/Fade";

function Main () {

    const { t } = useTranslation();
 
    return(
        <div className="overflow-hidden relative">
            <div className="flex flex-col xl:flex-row justify-between max-w-[1280px] mx-[20px] xl:mx-auto relative">
                <div className="mt-[160px]">
                    <p className="roboto text-[24px] xl:text-[40px] leading-[1.2]">{t('irs.1')}</p>
                    <p className="bebas text-[40px] xl:text-[90px] leading-[1.2] uppercase"><Trans>{t('irs.2')}</Trans></p>
                    <p className="roboto text-[24px] xl:text-[40px] leading-[1.2]">{t('irs.3')}</p>
                    <p className="roboto text-[14px] xl:text-[16px] leading-[1.5] mt-[20px] text-[#000000B2] max-w-[353px] font-[300]">{t('irs.4')}</p>
                    <a href="https://user.ameno-platform.one/login">
                        <div className="button_main mt-[40px]">
                            <p className="text-[14px] xl:text-[20px] roboto uppercase font-[500] text-white">{t('main.3')}</p>
                        </div>
                    </a>
                </div>
                <div className="xl:absolute right-0">
                    <Fade right>
                        <img src={main} alt="" />
                    </Fade>
                </div>
            </div>
            <div className="flex flex-col xl:flex-row justify-between max-w-[1280px] mx-[20px] xl:mx-auto relative mt-[50px] xl:mt-[100px]">
                <div className="xl:w-[600px]">
                    <p className="text-[16px] xl:text-[24px] roboto">{t('irs.5')}</p>
                    <p className="max-w-[600px] roboto text-[14px] xl:text-[16px] text-[#000000B2] font-[300] mt-[20px]">{t('irs.6')}</p>
                </div>
                <div className="mt-[20px] xl:mt-0 xl:w-[600px]">
                    <p className="text-[16px] xl:text-[24px] roboto">{t('irs.7')}</p>
                    <p className="max-w-[497px] roboto text-[14px] xl:text-[16px] text-[#000000B2] font-[300] mt-[20px]">{t('irs.8')}</p>
                </div>
            </div>
            <div className="flex flex-col xl:flex-row justify-between max-w-[1280px] mx-[20px] xl:mx-auto relative mt-[20px] xl:mt-[100px]">
                <div className="xl:w-[600px]">
                    <p className="text-[16px] xl:text-[24px] roboto">{t('irs.9')}</p>
                    <p className="max-w-[497px] roboto text-[14px] xl:text-[16px] text-[#000000B2] font-[300] mt-[20px]">{t('irs.10')}</p>
                </div>
                <div className="mt-[20px] xl:mt-0 xl:w-[600px]">
                    <p className="text-[16px] xl:text-[24px] roboto">{t('irs.11')}</p>
                    <p className="max-w-[497px] roboto text-[14px] xl:text-[16px] text-[#000000B2] font-[300] mt-[20px]">{t('irs.12')}</p>
                </div>
            </div>
        </div>
    );
}

export default Main;