import React from "react";

import { useTranslation, Trans } from "react-i18next";

import m1 from '../../img/Investing/m_one.png'
import m2 from '../../img/Investing/m_two.png'

import main from '../../img/Investing/main.png'

import Fade from "react-reveal/Fade";

function Main () {

    const { t } = useTranslation();
 
    return(
        <div className="relative overflow-hidden xl:overflow-visible">
            <div className="absolute -left-1/4 xl:left-0 top-[70px] xl:top-[180px] scale-50 xl:scale-100"><Fade left><img src={m1} alt=""/></Fade></div>
            <div className="absolute -right-1/4 xl:right-0 top-[260px] xl:top-[110px] scale-50 xl:scale-100"><Fade right><img src={m2} alt=""/></Fade></div>
            <div className="mx-[20px] xl:mx-auto flex flex-col max-w-[1280px]">
                <p className="bebas text-[32px] xl:text-[90px] mx-auto mt-[170px] leading-[1.2] z-50">{t('investing.1')}</p>
                <p className="roboto text-[24px] xl:text-[40px] mx-auto leading-[1.2] z-50">{t('investing.2')}</p>
                <p className="mx-auto max-w-[600px] text-[14px] xl:text-[16px] roboto font-[300] text-center text-[#000000B2] mt-[20px] z-50">{t('investing.3')}</p>
                
                <a href="https://user.ameno-platform.one/login" className="button_main mt-[40px] mx-auto z-50">
                    <div >
                        <p className="text-[14px] xl:text-[20px] roboto uppercase font-[500] text-white">{t('main.3')}</p>
                    </div>
                </a>
                <div className="absolute left-1/2 -translate-x-1/2 top-[400px] xl:top-[500px]">
                    <Fade bottom>
                        <img src={main} alt=""/>
                    </Fade>
                </div>
                <div className="flex flex-col xl:flex-row justify-between z-50 mt-[80px] xl:mt-[160px] gap-y-[20px] xl:gap-y-0">
                    <div className="xl:w-[497px] h-[189px] bg-white rounded-[40px] flex flex-col justify-center">
                        <p className="max-w-[300px] text-[16px] xl:text-[24px] roboto mx-[20px] xl:ml-[60px]">{t('investing.4')}</p>
                        <p className="max-w-[367px] text-[14px] xl:text-[16px] roboto mx-[20px] xl:ml-[60px] text-[#000000B2] font-[300] mt-[12px]">{t('investing.5')}</p>
                    </div>
                    <div className="xl:w-[497px] h-[189px] bg-white rounded-[40px] flex flex-col justify-center">
                        <p className="max-w-[300px] text-[16px] xl:text-[24px] roboto mx-[20px] xl:ml-[60px]">{t('investing.6')}</p>
                        <p className="max-w-[367px] text-[14px] xl:text-[16px] roboto mx-[20px] xl:ml-[60px] text-[#000000B2] font-[300] mt-[12px]">{t('investing.7')}</p>
                    </div>
                </div>
                <div className="flex flex-col xl:flex-row justify-between z-50 mt-[20px] xl:mt-[80px] xl:pb-[220px] gap-y-[20px] xl:gap-y-0">
                    <div className="xl:w-[497px] h-[189px] bg-white rounded-[40px] flex flex-col justify-center">
                        <p className="max-w-[300px] text-[16px] xl:text-[24px] roboto mx-[20px] xl:ml-[60px]">{t('investing.8')}</p>
                        <p className="max-w-[367px] text-[14px] xl:text-[16px] roboto mx-[20px] xl:ml-[60px] text-[#000000B2] font-[300] mt-[12px]">{t('investing.9')}</p>
                    </div>
                    <div className="xl:w-[497px] h-[189px] bg-white rounded-[40px] flex flex-col justify-center">
                        <p className="max-w-[300px] text-[16px] xl:text-[24px] roboto mx-[20px] xl:ml-[60px]">{t('investing.10')}</p>
                        <p className="max-w-[367px] text-[14px] xl:text-[16px] roboto mx-[20px] xl:ml-[60px] text-[#000000B2] font-[300] mt-[12px]">{t('investing.11')}</p>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default Main;